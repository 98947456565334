.carousel {
  -ms-overflow-style: none;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  gap: 0.5rem;
  /* hide scrollbar */
  &::-webkit-scrollbar {
    display: none;
  }
}
    
.carousel,
.slide {
  box-sizing: initial;
  display: flex;
  flex: none;
  scroll-snap-align: start;
}

.slide {
  height: auto;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.carousel .icon {
  color: #ecdc2b;
  text-shadow: 1px 1px 1px #000;
  font-size: 6rem;
  position: relative;
  z-index: 1;
  align-self: center;
  left: -1em;
  opacity: 0.5;
  margin-top: 0.5em;
}

.carousel label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  align-self: end;
  margin-bottom: 1em;
  align-items: center;
  flex-flow: row;
  z-index: 2;
}

.carousel label .activeDot {
  background-color: #ccc;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin: 0 0.25rem;
  transition: all 0.5s ease;
  background-color: #41ABE5;
  box-shadow: inset 2px 0px 2px -2px #333;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  top: -1em;
  height: 0em;
}

.dot {
  background-color: #ccc;
  border-radius: 50%;
  height: 0.8em;
  width: 0.8em;
  margin: 0 0.25rem;
  transition: all 0.5s ease;
}

.dots .active {
  background-color: #efe92e;
  box-shadow: inset 2px 0px 2px -2px #333;
}

/* transform active dot right 2em with scroll */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(2em);
  }
}
.carousel .activeDot {
  animation: scroll 0.5s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);

}