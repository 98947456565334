.article {
    margin: 2em;
}

.slim {
    margin: 0.5em 0;
}

blockquote {
    border-left: 0.4em solid lightgrey;
    padding: 0 1.5em;
    border-radius: 0.3em;
    margin-left: 2em;
}

.ql-editor ol, .ql-editor ul {
    padding-bottom: 1em;
}

h1 {
    font-family: 'Neuton', sans-serif;
    font-size: 2.5em;
}

h2 {
    /* font-family: 'Helvetica', sans-serif;     */
    font-size: 1.6em;
}

p {
    font-family: 'Helvetica', sans-serif;
    font-size: 1.1em;
}

.ui.label>a {
    opacity: 0.75;
}

.proposals div {
    margin-bottom: 0.2em;
}

.ui.vote-button:disabled {
    opacity: 1 !important;
}

.ui.form .field:last-child, .ui.form .fields:last-child .field {
    margin-bottom: 1em;
}

/* suppress scrolling when modal open */
body#modals.visible {
    overflow: hidden;
    height: 100vh;
}

.rc-slider-track {
    position: absolute;
    height: 4px;
    background-color: #d8c72e !important;
    border-radius: 6px;
}

.rc-slider-handle {
    position: absolute;
    width: 1.8em !important;
    height: 1.8em !important;
    margin-top: -0.8em !important;
    background-color: #fff;
    border: solid 3px #d8c72e !important;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    opacity: 0.8;
    touch-action: pan-x;
}

.reactEasyCrop_Container {
    background: black;
    height: 100vh !important;
    /* width: 100vh; */
    display: flex;
    flex-direction: column;
    position: fixed !important;
    overflow: hidden;
}

.reactEasyCrop_Controls {
    position: fixed;
    bottom: -60px;
    left: 0px;
    margin-bottom: 5.5rem;
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rc-slider {
    width: 95%;
}

.rc-slider-mark-text {
    transform: none !important;
}

.rc-slider-mark-text:last-child {
    right: 0px !important;
    left: auto !important;
}

.ui.primary.button {
    background-color: #dec800;
    transition: ease-in-out 0.2s;
}

.ui.primary.button:hover {
    background-color: #e6d622;
    transition: ease-in-out 0.2s;
}

.ui.primary.button:active, .ui.primary.buttons .button:active, 
.ui.primary.button:focus, .ui.primary.buttons .button:focus  {
    background-color: #fce279;
    transition: ease-in-out 0.2s;
}

/* Graph */
.bar {
    fill: #d8c72e;
}

/* Footer */

#root>div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.spacer {
    flex: 1;
}

.footer {
    clear: both;
    background-color: #f0e56b59 !important;
    padding: 2.5em 0 3em !important;
}

.ui.menu .item:before {
    background: none !important;
}

#welcomeMap {
    height: 80vh;
    width: 70vw;
    position: absolute;
    z-index: -1;
    margin-top: -0.5em;
    margin-left: 25vw;
    overflow: hidden;
}


/* mobile settings */
@media only screen and (max-width: 600px) {

    .dropzone {
        margin-left: -1em !important;
    }

    #welcomeMap {
        height: 100vh;
        width: 100vw;
        position: inherit;
        z-index: -1;
        margin-top: -3em;
        margin-left: 0;
        overflow: hidden;
        max-height: 30em;
    }

    .right.menu>.item>.username {
        display: none;
    }

    .ui.top.attached.menu .menu {
        font-size: 1.2em;
    }

    .footer .right {
        text-align: left !important;
    }
}